// > ON DOCUMENT READY
jQuery(document).ready(function($) {


	// >> Vars + Functions
	var
		devMode = false,
		mobileMenuLimit = 768,
		$urlHash = $(window.location.hash),
		$body = $( 'body' ),
		$header = $body.find('.site-header'),
		$mainMenu = $body.find('.site-header__menu'),
		$menuTrigger = $mainMenu.find('.site-header__menu-trigger'),
		$menu = $mainMenu.find('.menu'),
		$footerMenu = $body.find('.site-footer__main .menu'),
		$homeSlider = $('.home .slider'),
		$footerAuxContent = $body.find('.site-footer__auxiliary-content'),
		$goals = $body.find('.inteligencia-creativa-goals'),
		$tabs = $body.find('.la-inteligencia-creativa__tab'),
		$panelContainer = $body.find('.la-inteligencia-creativa-model-panels'),
		$panels = $body.find('.la-inteligencia-creativa__panel'),
		$newsItem = $body.find('.news-item__card'),
		preloadImages = new Array(),
		logoAnim = new Array(),
		$logo = $body.find('.site-header__brand'),
		animationInterval;

		/**
		 * Clean js styles from mobile menu
		 *
		 * @author X
		 * @return {[type]} [description]
		 */
		var cleanMobileMenu = function() {
			$menu.find('.sub-menu').removeAttr('style').removeClass('sub-menu--expanded');
			$menu.removeAttr('style').removeClass('menu--expanded');
			$menu.find('.has-expanded-submenu').removeClass('has-expanded-submenu');
		};

		/**
		 * Duplicate footer's auxiliary content and place into contact column
		 *
		 * @author X
		 * @return {[type]} [description]
		 */
		var placeFooterAuxContent = function() {
			var auxContent = $footerAuxContent.html();

			$body.find('.site-footer__main .menu > .menu-item:last-child').append('<div class="site-footer__duplicatedContent">' + auxContent + '</div>');
			$footerAuxContent.remove();
			var $footerContactMail = $body.find('.site-footer__duplicatedContent .social__item--mail a');
			$footerContactMail.attr('href', $footerContactMail.data('mail-link'));
			$footerContactMail.text($footerContactMail.data('mail-text'));

		};

		/**
		 * Add counters to Goal titles
		 *
		 * @author X
		 */
		var addGoalsCounters = function() {
			$goals.find('.inteligencia-creativa-goals__title').each(function(index, el) {
				var title = '0' + (index + 1) + '/ ' + $(this).text();
				$(this).text(title);
			});
		};

		/**
		 * Rewrite submenu links to use them as anchors inside the lvl1 page
		 *
		 * @author X
		 */
		var reWriteSubmenu = function($myMenu) {
			$myMenu.find('> .menu-item-has-children > a').each(function(index, el) {
				var lvl1Path = $(this).attr('href');

				$(this).siblings('.sub-menu').find('> li > a').each(function(index, el) {
					var
						subURL = $(this).attr('href'),
						subPath = subURL.split('/'),
						subPathLength = subPath.length;

					$(this).attr('href', lvl1Path + '#' + subPath[subPathLength - 2] );
				});
			});
		};

		/**
		 * Deselect possible selected tabs on load an reset panels
		 *
		 * @author X
		 */
		var unselectTabs = function() {
			if ($tabs.is('.la-inteligencia-creativa__tab--selected')) {
				$tabs.removeClass('la-inteligencia-creativa__tab--selected');
			}
			if ($panels.is('.la-inteligencia-creativa__panel--selected')) {
				$panelContainer.removeAttr('style');
				$panels.removeClass('la-inteligencia-creativa__panel--selected').removeAttr('style');
			}
		};

		/**
		 * Select this Tab and show the correct panel
		 *
		 * @author X
		 * @param  {Object} $tab Current Tab
		 */
		var selectTab = function($tab) {
			var panelID = '#' + $tab.data('panel');
			$tab.parent().addClass('la-inteligencia-creativa__tab--selected');
			$(panelID).addClass('la-inteligencia-creativa__panel--selected').show();
			$panelContainer.slideDown('fast',function(){
				goToPanel(panelID);
			});

		};

		var goToPanel = function(targetID) {
			var
				offset = 0,
				$target = $(targetID);

				console.log('>> TARGET:')
				console.log('>>> #' + targetID);
				console.log('>>> ' + $target.attr('id'));
			if ($(window).width() >= 768) {
				offset = 180 + 30;
			} else {
				offset = 157 + 30;
			}
			$('html, body').animate({
				scrollTop: $(targetID).offset().top - offset
			}, 500);
		};

		/**
		 * Close current tab
		 *
		 * @author X
		 * @param  {Object} $tab Current tab
		 */
		var closeTab = function($tabToClose, $tabToOpen) {
			var panelID = '#' + $tabToClose.data('panel');
			$tabToClose.parent().removeClass('la-inteligencia-creativa__tab--selected');
			if ($tabToOpen != null) {
				$panelContainer.slideUp('fast', function() {
					$(panelID).removeClass('la-inteligencia-creativa__panel--selected').hide();
					selectTab($tabToOpen);
				});
			} else {
				$panelContainer.slideUp('slow', function() {
					$(panelID).removeClass('la-inteligencia-creativa__panel--selected').hide();
				});
			}
		};

		/**
		 * Adjust each news item so all have the same height
		 */
		var setNewsHeight = function() {
			if ($(window).width() >= 768) {
					var itemMaxHeight = 0;
					$newsItem.each(function(index, el) {
						var itemHeight = $(this).height();
						if ( itemHeight >= itemMaxHeight ) {
							itemMaxHeight = itemHeight;
						}
					});

					$newsItem.addClass('has-fixed-height').height(itemMaxHeight);
			} else {
				if ( $newsItem.is('.has-fixed-height') ) {
					$newsItem.each(function(index, el) {
						if ($(this).hasClass('has-fixed-height')) {
							$(this).removeAttr('style').removeClass('has-fixed-height');
						}
					});
				}

			}
		};

		/**
		 * SmoothScroll to a section within the current page
		 *
		 * @author X
		 * @param  {Object} $target Object to go to.
		 */
		var goToSection = function( $target ) {
			if ($target.length) {
				var itemIndex = $('.content-block').index( $target );
				var offset = 0;
				if (itemIndex > 0) {
					if ($(window).width() < 768 ) {
						offset = 157;
					} else {
						offset = 180;
					}
				} else {
					if ($(window).width() < 768 ) {
						offset = 157;
					} else {
						offset = 290;
					}
				}
				$('html, body').animate({
					scrollTop: $target.offset().top - offset
				}, 1000);
			}
		};

		/**
		 * Preload Logo animation
		 * from: https://perishablepress.com/3-ways-preload-images-css-javascript-ajax/
		 */
		var preload = function() {
			for (i = 0; i < preload.arguments.length; i++) {
				preloadImages[i] = new Image()
				preloadImages[i].src = preload.arguments[i]
			}
		};

		var changeLogoImage = function(newImagePath) {
			$logo.css('background-image', "url('" + newImagePath + "')");
		};

		var animateLogo = function() {
			if ( $(window).width() >= 1140 ) {
				if ( !$body.hasClass('has-animated-logo') ) {
					$body.addClass('has-animated-logo');
					changeLogoImage( logoAnim[0]);
				}
			} else {
				if ( $body.hasClass('has-animated-logo') ) {
					clearInterval(animationInterval);
					$logo.removeAttr('style');
					$body.removeClass('has-animated-logo');
				}
			}
		};






	// >> Preload images
	logoAnim[0] = inteligencia_creativa.themeUrl + 'assets/img/logo-anim/logo-anim-complete.gif';

	preload(
		logoAnim[0]
	);
	animateLogo();





	// >> Smoothscrolling on load
	goToSection($urlHash);




	// >> Header
	var waypoints = $header.waypoint(function(direction) {
		$body.toggleClass('has-compact-header');
	}, {
		offset: '-30'
	});





	// >> Rewrite Submenu
	reWriteSubmenu($menu);
	reWriteSubmenu($footerMenu);







	// >> Menu mobile
	// >>> Trigger
	$menuTrigger.on('click', function(event) {
		$menu.toggleClass('menu--expanded');
		if ($menu.hasClass('menu--expanded')) {
			$menu.slideDown();
		} else {
			$menu.slideUp();
		}
	});






	// >>> Submenu mobile
	$menu.find('.menu-item-has-children > a').on('click', function(event) {
		event.preventDefault();
		if ( $(window).width() < mobileMenuLimit ) {
			$menu.find('.has-expanded-submenu').removeClass('has-expanded-submenu');
			$menu.find('.sub-menu').removeClass('.sub-menu--expanded').hide();
			$(this).addClass('has-expanded-submenu').siblings('.sub-menu').addClass('sub-menu--expanded').slideDown();
		}
	});

	$menu.find('.current-menu-item .sub-menu a').on('click', function(event) {
		event.preventDefault();
		// http://localhost:3000/croma-la-inteligencia-creativa/serveis/#serveis
		var
			url = $(this).attr('href'),
			urlParts = url.split('#'),
			myHash = '#' + urlParts[1]
			$myPageTarget = $(myHash);

			goToSection($myPageTarget);
	});
	$menu.hover(function() {
		$menu.find('> .current-menu-item > a').addClass('has-hidden-decoration');
	}, function() {
		$menu.find('> .current-menu-item > a').removeClass('has-hidden-decoration');

	});

	$footerMenu.find('.current-menu-item .sub-menu a').on('click', function(event) {
		event.preventDefault();
		// http://localhost:3000/croma-la-inteligencia-creativa/serveis/#serveis
		var
			url = $(this).attr('href'),
			urlParts = url.split('#'),
			myHash = '#' + urlParts[1]
			$myPageTarget = $(myHash);

			goToSection($myPageTarget);
	});





	// >> Slider
	if ($('.home .slider__item').length > 1) {
		$homeSlider.slick({
			arrows: false,
			dots: true,
			autoplay: true,
			autoplaySpeed: 7000
		})
	}





	// >> Footer
	if ( $footerAuxContent.length ) {
		placeFooterAuxContent();
	}





	// >> Goals count
	if ( $goals.length ) {
		addGoalsCounters();
	}





	// >> TABS
	if ( $tabs.length ) {
		unselectTabs();

		$tabs.each(function(index, el) {
			$(this).children('a').on('click', function(event) {
				if ( $(window).width() >= 1024 ) {
					event.preventDefault();

					if ( $(this).parent().hasClass('la-inteligencia-creativa__tab--selected')) {
						closeTab( $(this), null);
					} else {
						if ($body.find('.la-inteligencia-creativa__tab--selected').length) {
							closeTab($body.find('.la-inteligencia-creativa__tab--selected a'), $(this)	);
						} else {
							selectTab( $(this) );
						}
					}

				}
			});
		});
	}





	// > News
	if ($newsItem.length) {
		setNewsHeight();
	}





	// >> BreakPoints
	$(window).setBreakpoints({
		distinct: true,
		// array of widths in pixels where breakpoints
		// should be triggered
		breakpoints: [
			768,
			1024
		]
	});

	$(window).bind('enterBreakpoint768',function() {
		cleanMobileMenu();
	});

	$(window).bind('exitBreakpoint1024',function() {
		unselectTabs();
	});





	// >> Responsive snitch
	if ( devMode ) {
		$body.width_snitch({
			style:{}
		})
	}





	// > onResize
	$(window).resize(function(event) {
		if ($newsItem.length) {
			setNewsHeight();
		}
		animateLogo();
	});




});
